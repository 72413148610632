
import { defineComponent, ref } from "vue";

interface Filter {
  quantity: string;
}

export default defineComponent({
  name: "product-filter",
  components: {},
  methods: {
    filter(value) {
      this.$emit('filterByQuantity', value)
    }
  },
  setup() {
    const data = ref<Filter>({
      quantity: "both",
    });

    return {
      data,
    };
  },
});
