
import {defineComponent, onMounted, ref, watch} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddProductModal from "@/components/modals/forms/AddProductModal.vue";
import EditProductModal from "@/components/modals/forms/EditProductModal.vue";
import AssignProductModal from "@/components/modals/forms/AssignProductModal.vue";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ProductFilter from "@/components/dropdown/ProductFilter.vue";
import {IStock} from "@/views/apps/stocks/StocksListing.vue";
import router from "@/router";

interface IProduct {
  ID: number;
  skuID: string;
  title: string;
  box: string;
  min: number;
  max: number;
  quantity: number;
}

export default defineComponent({
  name: "products-listing",
  components: {
    Datatable,
    AddProductModal,
    EditProductModal,
    AssignProductModal,
    ProductFilter
  },
  setup() {
    const store = useStore();
    const checkedProducts = ref([]);
    const tableHeader = ref([
      /*{
        key: "checkbox",
      },*/
      {
        name: "SKU ID",
        key: "skuID",
        sortable: true,
      },
      {
        name: "Title",
        key: "title",
        sortable: true,
      },
      {
        name: "Box",
        key: "box",
        sortable: false,
      },
      {
        name: "Min",
        key: "min",
        sortable: true,
      },
      {
        name: "Max",
        key: "max",
        sortable: true,
      },
      {
        name: "Quantity",
        key: "quantity",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);
    const tableData = ref<Array<IProduct>>([]);
    const initProducts = ref<Array<IProduct>>([]);
    const isAdmin = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const stocksLoading = ref<boolean>(false);
    const stocks = ref<Array<IStock>>([]);

    const getUser = () => {
      store.dispatch(Actions.USER_PROFILE)
          .then(({data}) => {
            isAdmin.value = data.role == "admin"
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const fetchData = (option = 'both') => {
      loading.value = true
      stocksLoading.value = true

      store.dispatch(Actions.PRODUCTS_LIST, option)
          .then(({data}) => {
            for (let item of data) {
              if (item.min > item.quantity) {
                item.color = 'danger'
              } else {
                item.color = 'success'
              }
            }

            tableData.value = data
            loading.value = false
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

      store.dispatch(Actions.STOCKS_LIST, option)
          .then(({data}) => {
            stocks.value = data
            stocksLoading.value = false
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    onMounted(() => {
      fetchData()
      getUser()
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Products Listing", ["Apps", "Products"]);
    });

    watch(() => tableData.value, first => {
      initProducts.value.splice(0, initProducts.value.length, ...first);
    });

    const selectedProduct = ref<IProduct>()

    const selectProduct = item => {
      selectedProduct.value = item
    }

    const deleteProduct = (id) => {
      store.dispatch(Actions.PRODUCT_DELETE, id)
          .then(() => {
            for (let i = 0; i < tableData.value.length; i++) {
              if (tableData.value[i].ID === id) {
                tableData.value.splice(i, 1);
              }
            }
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    };

    const deleteFewProducts = () => {
      checkedProducts.value.forEach((item) => {
        deleteProduct(item);
      });
      checkedProducts.value.length = 0;
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initProducts.value);
      if (search.value !== "") {
        let results: Array<IProduct> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const transactions = id => {
      router.push({name: 'apps-transactions-listing', params: {section: 'product', id}})
    }

    return {
      tableData,
      tableHeader,
      search,
      checkedProducts,
      selectedProduct,
      isAdmin,
      loading,
      stocksLoading,
      stocks,
      deleteProduct,
      searchItems,
      deleteFewProducts,
      selectProduct,
      fetchData,
      transactions
    };
  },
});

export {IProduct}
