
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";

export default defineComponent({
  name: "add-product-modal",
  props: ["reloadData"],
  components: {},
  setup(props) {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      skuID: "",
      title: "",
      box: "",
      min: 0,
      max: 0
    });

    const rules = ref({
      skuID: [
        {
          required: true,
          message: "SKU ID is required",
          trigger: "change",
        },
      ],
      title: [
        {
          required: true,
          message: "Title is required",
          trigger: "change",
        },
      ],
      min: [
        {
          required: true,
          min: 0,
          message: "Min is required",
          trigger: "change",
        },
      ],
      max: [
        {
          required: true,
          min: 0,
          message: "Max required",
          trigger: "blur",
        },
      ]
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          setTimeout(() => {
            loading.value = false;

            store
                .dispatch(Actions.ADD_PRODUCT, formData.value)
                .then(() => {
                  loading.value = false;
                  props.reloadData()
                  hideModal(addProductModalRef.value);
                })
                .catch(() => {
                  loading.value = false;

                  Swal.fire({
                    text: store.getters.getErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Try again!",
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-danger",
                    },
                  });
                });
          }, 2000);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addProductModalRef,
    };
  },
});
